import React from "react";
import Footer from "./Footer";
import styled from "styled-components";
import {
  Section,
  SectionHeading,
  ExampleCard,
  ExampleImageHolder,
  ExampleImage,
  ExampleTextHolder,
  ExampleHeading,
  ExampleDescription,
  ReadMore
} from "./SharedStyles";
import { articleList } from "./ProjectArticles";

const images = require.context("./img", true);

const ExampleContainer = styled.div`
  margin-top: 3rem;
`;

const Projects = props => {
  return (
    <Section contentVisible={props.contentVisible}>
      <SectionHeading>I like to work on side projects</SectionHeading>
      <React.Fragment>
        <p>
          Often these are ways for me to develop skills that are relevant to my
          work. But sometimes it's just for the fun of it.
        </p>
        <p>Here are a few examples:</p>
        <ExampleContainer>
          {articleList.map((article, articleIndex) => {
            const fullImage = images(`./${article.image}-full.png`);
            const smallImage = images(`./${article.image}-small.png`);
            return (
              <ExampleCard
                key={articleIndex}
                tabIndex="0"
                aria-label={`Case study: ${article.title}`}
                onClick={() =>
                  props.handleNavChange(
                    "other-projects",
                    true,
                    article.slug,
                    true
                  )
                }
              >
                <ExampleImageHolder aria-hidden="true">
                  <picture>
                    <source
                      media={`(min-width: 34.05rem) and (orientation: landscape),
                                (min-width: 34.05rem) and (orientation: portrait)
                                and (min-device-width: 768px)`}
                      srcSet={smallImage}
                    />
                    <ExampleImage src={fullImage} />
                  </picture>
                </ExampleImageHolder>
                <ExampleTextHolder>
                  <div>
                    <ExampleHeading aria-hidden="true">
                      {article.title}
                    </ExampleHeading>
                    <ExampleDescription>{article.summary}</ExampleDescription>
                  </div>
                  <ReadMore>
                    <span>Read more</span> →
                  </ReadMore>
                </ExampleTextHolder>
              </ExampleCard>
            );
          })}
        </ExampleContainer>
      </React.Fragment>
      <Footer />
    </Section>
  );
};

export default Projects;
