import styled from "styled-components";

export const Wrapper = styled.div`
  width: 60rem;
  margin: 0 auto;
  justify-content: center;
  padding-left: calc(100vw - 100%);

  @media (max-width: ${props => props.theme.midBreak}) {
    box-sizing: border-box;
    width: 100%;
    padding: 0 4rem;
  }

  @media (max-width: ${props => props.theme.smallBreak}),
    (max-device-width: 680px) and (orientation: portrait) {
    padding: 0 2rem;
    min-width: 30rem;
  }
`;

export const Section = styled.section`
  position: absolute;
  width: 100%;
  visibility: ${props => (props.contentVisible ? "visible" : "hidden")};
  opacity: ${props => (props.contentVisible ? 1 : 0)};
  max-height: ${props => (props.contentVisible ? "1000rem" : 0)};
  overflow: ${props => (props.contentVisible ? "" : "hidden")};
  transition: visibility ${props => (props.contentVisible ? "1s" : "")} 0.2s,
    opacity ${props => (props.contentVisible ? "1s" : "")} 0.2s,
    max-height 0s 0.2s;
`;

export const SectionHeading = styled.h2`
  margin-bottom: ${props => props.theme.standardSpacing};
  margin-top: ${props => props.theme.standardSpacing};
  font-family: ${props => props.theme.headerFont};
  font-size: 3.4rem;
  font-weight: 600;

  @media (max-width: ${props => props.theme.midBreak}) {
    font-size: 3rem;
  }

  @media (max-width: ${props => props.theme.smallBreak}),
    (max-device-width: 680px) and (orientation: portrait) {
    font-size: 2.8rem;
  }
`;

export const SubsectionHeading = styled.h3`
  margin-bottom: ${props => props.theme.standardSpacing};
  margin-top: ${props => (props.isAnchor ? "-6rem" : "4rem")};
  padding-top: ${props => (props.isAnchor ? "10rem" : 0)};
  font-family: ${props => props.theme.smallCapsFont};
  font-size: 2.4rem;
  font-weight: 600;
  font-feature-settings: unset;
  line-height: 4.2rem;

  > span:first-child {
    background-color: ${props => props.theme.backdropBrown};
    padding: 0.7rem 0.5rem 0.2rem 0.5rem;
    text-transform: uppercase;

    > span {
      margin-right: -0.1rem;
      font-weight: 500;
      font-size: 3rem;
      
      @media (max-width: ${props => props.theme.midBreak}) {
        font-size: 2.8rem;
      }

    }
  }

  @media (max-width: ${props => props.theme.midBreak}) {
    font-size: 2.2rem;
    line-height: 4rem;
  }
}
`;

export const SubjectHeading = styled.h4`
  margin-bottom: ${props => props.theme.standardSpacing};
  margin-top: ${props => (props.extraSpace ? "2.5rem" : "2rem")};
  font-family: ${props => props.theme.headerFont};
  font-size: 2.6rem;
  font-weight: 600;
  line-height: 3.5rem;

  @media (max-width: ${props => props.theme.midBreak}) {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
}
`;

export const BaseButton = styled.button`
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  border: 0;
  background: none;

  &::-moz-focus-inner {
    border: 0;
  }

  &:focus {
    outline: 2px dashed ${props => props.theme.paleGrey};
    outline-offset: 2px;
  }
`;

export const ExampleCard = styled.section`
  width: 95%;
  height: 22rem;
  display: inline-block;
  vertical-align: top;
  z-index: 10;
  position: relative;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 0.2rem 0.8rem 0.4rem rgba(240, 240, 240, 0.6);
  transition: transform 0.2s;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0);

  &:hover,
  &:focus {
    cursor: pointer;
    transform: translateX(1rem);
  }

  &:focus {
    outline: 2px dashed ${props => props.theme.paleGrey};
    outline-offset: 2px;
  }

  &:first-child {
    margin-right: 2rem;
  }

  @media (max-width: ${props => props.theme.midBreak}) {
    width: 100%;
  }

  @media (max-width: 34rem),
    (max-device-width: 680px) and (orientation: portrait) {
    height: unset;
  }

  @media (hover: none), (-moz-touch-enabled: 1), (pointer: coarse) {
    &:hover,
    &:focus {
      transform: none;
    }
  }
`;

export const ExampleImageHolder = styled.div`
  border-radius: 0.5rem 0 0 0.5rem;
  display: block;
  float: left;
  height: 100%;
  width: 40%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;

  @media (max-width: ${props => props.theme.midBreak}) {
    width: 30%;
  }

  @media (max-width: 34rem),
    (max-device-width: 680px) and (orientation: portrait) {
    width: 100%;
    border-radius: 0.5rem 0.5rem 0 0;
  }
`;

export const ExampleImage = styled.img`
  display: block;
  border: 0;
  width: 100%
  opacity: 0.95;

  &:hover {
    color: unset;
    cursor: pointer;
  }

  @media (max-width: ${props => props.theme.midBreak}) {
    width: auto;
    height: 30rem;
  }

  @media (max-width: 34rem),
  (max-device-width: 680px) and (orientation: portrait) {
    width: 100%;
    height: auto;
  }
`;

export const ExampleTextHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  float: left;
  height: 100%;
  width: 60%;
  padding: 2rem 2rem;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: ${props => props.theme.midBreak}) {
    width: 70%;
  }

  @media (max-width: 34rem),
    (max-device-width: 680px) and (orientation: portrait) {
    height: auto;
    width: 100%;
  }
`;

export const ExampleHeading = styled.h5`
  font-size: 2rem;
  margin: 0 0 1.2rem 0;
`;

export const ExampleDescription = styled.p`
  font-size: 1.6rem;
  line-height: 2.6rem;
  margin: 1.2rem 0 0 0;
`;

export const ReadMore = styled.p`
  font-size: 1.6rem;
  line-height: 2.6rem;
  margin: 1.2rem 0 0 0;

  span {
    text-decoration: underline;
  }
`;

export const ImageHolder = styled.figure`
  width: 100%;
  display: block;
  margin: 3rem 0;
`;

export const ImageSizer = styled.div`
  width: 100%;
  display: block;
  padding-bottom: 60%;
  background-color: ${props => props.theme.paleGrey};
`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  display: block;
`;

export const Caption = styled.figcaption`
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${props => props.theme.midGrey};
  margin: 1rem 0 0 0;
  text-align: center;
  font-style: italic;
`;

export const Objective = styled.p`
  &::before {
    content: "Objective: ";
    font-weight: 700;
  }
  padding: 2rem 2rem;
  font-style: italic;
  //background-color: #f1f1f1;
  border: 2px solid ${props => props.theme.paleGrey};
  border-radius: 0.5rem;
  font-size: 2.2rem;
  color: ${props => props.theme.midGrey};
`;
