import React from "react";
import Footer from "./Footer";
import styled from "styled-components";
import { Section, SectionHeading, SubsectionHeading } from "./SharedStyles";

const JobInfo = styled.section`
  margin-bottom: 2.4rem;

  p {
    margin: 0 0 0.5rem 0;
  }

  ul {
    margin-top: 0;
  }
`;

const CV = (props) => {
  return (
    <Section contentVisible={props.contentVisible}>
      <SectionHeading>Please email me for a copy of my CV</SectionHeading>
      <p>
        I like to keep my CV fairly brief, which means leaving some things out.
        So if you'd like a copy of my CV, send me an email and I'll put one
        together that's appropriate for the role.
      </p>
      <p>
        However, anything that you might find in my CV is on this website. I
        have included my work history and education below. If you want to see
        examples of things I've worked on, have a look in my portfolio.
      </p>
      <SubsectionHeading>
        <span>
          <span>W</span>ork history
        </span>
      </SubsectionHeading>
      <JobInfo>
        <p>
          <b>Fresha</b> (June 2021 – August 2021) *
        </p>
        <p>
          I created processes and documents to help designers and product
          manager to conduct better research. I developed new processes for
          recruiting research participants and sharing parter feedback.
        </p>
      </JobInfo>
      <JobInfo>
        <p>
          <b>Deliveroo</b> (February 2021 – April 2021) *
        </p>
        <p>
          I conducted remote moderated interviews and user testing sessions
          to build a better understanding of needs and expectations realating
          to 3 specific areas of the consumer app.
        </p>
      </JobInfo>
      <JobInfo>
        <p>
          <b>Bulb</b> (May 2020 – August 2020) *
        </p>
        <p>
          I conducted interviews to help Bulb better understand how people use
          and feel about energy usage data, and followed this with concept
          testing. I also led the design and implementation of a new GDPR
          process for research at Bulb.
        </p>
      </JobInfo>
      <JobInfo>
        <p>
          <b>Deliveroo</b> (December 2019 – April 2020) *
        </p>
        <p>
          I worked on Deliveroo's consumer product and managed the research for
          3 multidisciplinary squads. I conducted remote user testing for rapid
          product development and interviews about motivations and the early
          effects of COVID-19.
        </p>
      </JobInfo>
      <JobInfo>
        <p>
          <b>Modern Human</b> (October 2019 – November 2020) *
        </p>
        <p>
          A short contract with a small design and research agency, working on a
          project for the BBC. I conducted in-home interviews with parents and
          their children to understand their media consumption habits.
        </p>
      </JobInfo>
      <JobInfo>
        <p>
          <b>Lyst</b> (February 2016 – May 2019)
        </p>
        <p>
          I was the senior user researcher at Lyst, a fashion ecommerce
          start-up. I worked closely with product managers, designers, engineers
          and data scientists to help guide product strategy and development. I
          managed a mid-level user researcher and I mentored designers and PMs
          to improve their user research skills.
        </p>
      </JobInfo>
      <JobInfo>
        <p>
          <b>DigitasLBi</b> (September 2010 – January 2016)
        </p>
        <p>
          DigitasLBi (now Digitas) is a full-service digital marketing agency
          and part of Publicis Groupe. I worked there as a user researcher,
          strategist and data analyst. I worked for a range of clients,
          including AstraZeneca, Barclays, Eon, Honda, Premier Inn, EA, Saudi
          Aramco and many others.
        </p>
      </JobInfo>
      <p>
        * <i>Contract roles have an asterisk</i>
      </p>
      <SubsectionHeading>
        <span>
          <span>E</span>ducation
        </span>
      </SubsectionHeading>
      <JobInfo>
        <p>
          <b>University of Cambridge</b> (2006 – 2009)
        </p>
        <p>BA Hons History (II.i)</p>
      </JobInfo>
      <JobInfo>
        <p>
          <b>Online courses</b> (2010 – present):
        </p>
        <ul>
          <li>UX Research and Design (UX501x-508x) - MichiganX</li>
          <li>Data Analysis Tools - Wesleyan University</li>
          <li>Computing for Data Analysis - Johns Hopkins University</li>
          <li>Social Network Analysis - University of Michigan</li>
          <li>Introduction to Databases - Stanford Online</li>
          <li>Introduction to Computer Science - Udacity</li>
          <li>Mobile App Development with React Native (CS50M)- HarvardX</li>
          <li>Introduction to JavaScript - W3Cx</li>
        </ul>
        <p>
          Some of these courses offered an official certificate that you could
          earn by completing graded assignments and paying a fee. I took the
          free versions of these courses instead. If any assignments were
          unavailable because of this, I practised what I had learned with other
          projects instead.
        </p>
      </JobInfo>
      <JobInfo>
        <p>
          <b>Recent reading:</b>
        </p>
        <ul>
          <li>Sprint by Jake Knapp</li>
          <li>Microstyle: The Art of Writing Little by Christopher Johnson</li>
          <li>The User is Always Right by Steve Mulder</li>
          <li>Thinking Statistically by Uri Bram</li>
          <li>Interviewing Users by Steve Portigal</li>
          <li>The User Experience Team of One by Leah Buley</li>
          <li>Put it in Writing by John Whale</li>
        </ul>
      </JobInfo>
      <Footer />
    </Section>
  );
};

export default CV;
