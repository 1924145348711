import React, { Component } from "react";
import Footer from "./Footer";
import {
  Section,
  SectionHeading,
  SubjectHeading,
  ImageHolder,
  ImageSizer,
  Image,
  Caption,
  Objective,
} from "./SharedStyles";

export const articleList = [
  {
    name: "Audience insight",
    approach:
      "Every product team needs to understand who they are designing for. The most " +
      "important thing is finding the right participants and conducting in-depth " +
      "research with them. If possible, this information should then be combined with " +
      "large-scale behavioural data.",
    examples: [
      {
        slug: "lyst-audience",
        title: "The Lyst Audience",
        summary:
          "I used interviews, diary studies and surveys to help Lyst's product " +
          "teams understand their users and potential users.",
        image: "faces",
      },
      {
        slug: "fashion-personas",
        title: "Fashion personas",
        summary:
          "At the beginning of 2019, I created a set of personas for a series of " +
          "workshops and design sprints.",
        image: "persona",
      },
    ],
  },
  {
    name: "User testing",
    approach:
      "There are lots of ways to do user testing. First, I determine what methods " +
      "are appropriate for the job, then it's usually a question of time versus quality. " +
      "That judgement can only be made on a case-by-case basis.",
    examples: [
      {
        slug: "rapid-app-prototyping",
        title: "Rapid app prototyping",
        summary:
          "I worked with a product designer and a product manager to design and " +
          "test prototypes and iterate on them in one-week sprints.",
        image: "video-call",
      },
    ],
  },
  {
    name: "Customer feedback",
    approach:
      "Often user testing is conducted with people who aren't your existing users. So " +
      "it's important to have other ways to keep learning about your real users. I like " +
      "to collect comments from people who come to the website or app and then to " +
      "recruit some of them for remote interviews. Tools like Ethnio are great for this.",
    examples: [
      {
        slug: "making-the-ux-score",
        title: "Making the UX Score",
        summary:
          "I replaced Lyst's NPS implementation with a new system that focused " +
          "attention on the most valuable data.",
        image: "ux-score",
      },
    ],
  },
  {
    name: "Communication",
    approach:
      "Research is useless if the output isn't communicated well. The best communication " +
      "method depends entirely on your team and how they like to work. Written reports, " +
      "video clips, presentations, workshops and personas are all communication tools. It's " +
      "a question of finding the right tool for a given situation.",
    examples: [
      {
        slug: "research-video-lunches",
        title: "Research video lunches",
        summary:
          "I ran research video viewing sessions at lunchtime to draw more attention " +
          "to our users and to increase engagement with existing research findings.",
        image: "pizza",
      },
    ],
  },
  {
    name: "Team leadership",
    approach:
      "A happy team is crucial. When it comes to line managing, or just being a team member, " +
      "I make sure that I do what I can help people with their problems and keep their spirits " +
      "up. I also enjoy working on processes to make teams more effective.",
    examples: [
      {
        slug: "fixing-retros",
        title: "Fixing retros",
        summary:
          "I streamlined the UX team retros and worked on ways to keep track of " +
          "recurring issues so that we could keep improving.",
        image: "retro",
      },
    ],
  },
  {
    name: "Hiring",
    approach:
      "I've been through several periods where slow or poor hiring has had serious knock-on " +
      "effects. I always try to get involved in making the company attractive to new employees, " +
      "and making sure that the interview process is effective and fair.",
    examples: [
      {
        slug: "user-research-hiring-process",
        title: "User research hiring process",
        summary:
          "I designed a hiring process for user researchers that focused on testing key " +
          "research skills in a reliable and ethical way.",
        image: "tradar",
      },
    ],
  },
];

export const LystAudience = (props) => (
  <Section contentVisible={props.contentVisible}>
    <SectionHeading>The Lyst Audience</SectionHeading>
    <ImageHolder>
      <ImageSizer>
        <Image
          alt="A grid of pixelated faces"
          src={props.images(`./faces-full.png`)}
        />
      </ImageSizer>
      <Caption>These images are not based on real participants</Caption>
    </ImageHolder>
    <p>
      When I joined Lyst in 2016, the company didn't have a clear idea of who
      its users were or why they were using Lyst. Fixing this was my priority.
    </p>
    <Objective>
      To give Lyst a foundation of understanding about its audience, which could
      then inform the product strategy.
    </Objective>
    <SubjectHeading>Context and constraints</SubjectHeading>
    <p>
      Before I joined Lyst, the only research had been a bit of user testing and
      maybe a survey done by an agency, so the audience research had to start
      pretty much from scratch.
    </p>
    <p>
      I was the only researcher at Lyst for the majority of this project. And I
      had a fairly small research budget at my disposal.
    </p>
    <SubjectHeading>Defining the audience</SubjectHeading>
    <p>
      We wanted this research to help us decide which direction to take the
      product in. So it wasn’t enough to just look at Lyst’s current users or
      people similar to them.
    </p>
    <p>
      I had to look at fashion shoppers more broadly. By learning about other
      kinds of fashion shoppers, we could discover needs and behaviours that the
      current product wasn’t addressing. This could help us to create something
      new and hopefully better.
    </p>
    <p>
      The difficulty came from judging how broad an audience we should include.
      If we defined the audience fairly narrowly, there would be more time and
      money to understand that audience in more detail.
    </p>
    <p>
      I spoke to other people in the company to get a better idea of the
      business strategy and where the limits were. Lyst had a lot of
      partnerships with premium fashion retailers, but we didn’t want to be
      limited to just premium shoppers in this research. However, I was allowed
      to rule out budget shoppers.
    </p>
    <ImageHolder>
      <ImageSizer>
        <Image
          alt="A Venn diagram showing the audience groups"
          src={props.images(`./audience-groups-full.png`)}
        />
      </ImageSizer>
      <Caption>
        The size of the rings and the amount of overlap are arbitrary
      </Caption>
    </ImageHolder>
    <p>
      I divided the audience into four groups: Lyst users, premium fashion
      shoppers, people who took an interest in what they wore and would
      sometimes spend quite a lot of money, and everyone else.
    </p>
    <p>
      I felt that it was important to include Lyst’s current users in the
      research, and that this would help us to get a better idea of who Lyst was
      appealing to at that time. This could then be compared with fashion
      shoppers in general.
    </p>
    <SubjectHeading>Research methods</SubjectHeading>
    <p>
      Before any of the research began, I set up chats with different people in
      the business to understand how audience insight might be useful to them.
      This helped me to decide which research methods would be appropriate and
      to develop the content for the discussion guides.
    </p>
    <p>
      The majority of the research came from one-on-one remote interviews,
      conducted through a video chat. This allowed me to include people from
      different countries without needing lots of extra time or money.
    </p>
    <p>
      Remote interviews have their flaws. In an ideal world, I would have spent
      more time conducting observational research to get better behavioural
      data. It wouldn’t have been possible to do this at the same scale, so I
      conducted some diary studies with a smaller group of participants. This
      helped to provide some balance.
    </p>
    <p>
      Finally, once all of the qualitative data had been collected and analysed,
      I used that information to design a survey.
    </p>
    <p>
      The qualitative data told me a lot about what attitudes and behaviours
      were out there, but I wanted to be able to have more confidence that they
      existed at scale.
    </p>
    <SubjectHeading>Participant recruitment</SubjectHeading>
    <p>
      Recruiting the right participants is essential for this kind of research,
      so I spent a lot of time at the beginning of the project thinking about
      it, talking to people about it, and working on recruitment briefs.
    </p>
    <p>
      I recruited the participants partly through research recruitment agencies
      and partly from the Lyst website and app (using a tool called Ethnio).
    </p>
    <p>
      For the recruitment of Lyst users, I was able to look through the
      analytics to identify interesting attributes and behaviours. This helped
      me to make sure that I was recruiting a diverse selection of users, and it
      was useful input into the research analysis as well.
    </p>
    <p>
      The survey participants were recruited by the same company that hosted the
      survey for us. I made sure that they were drawn from a nationally
      representative sample.
    </p>
    <SubjectHeading>Personas</SubjectHeading>
    <ImageHolder>
      <ImageSizer>
        <Image
          alt="A sketch of different kinds of personas"
          src={props.images(`./persona-sketches-full.png`)}
        />
      </ImageSizer>
      <Caption>I looked at lots of different kinds of personas</Caption>
    </ImageHolder>
    <p>
      I did a lot of reading about personas before starting the project. I also
      spoke to people who had worked for companies that used them.
    </p>
    <p>
      There are a lot of valid criticisms of personas, but I felt that it was
      necessary to have something that represented the company’s audience that
      was accessible. Personas seemed like a good first step for the company and
      something that we could experiment with and learn from.
    </p>
    <p>
      I helped to produce two rounds of personas during the course of this
      project. I have a separate case study on the second round, so I won’t go
      into any more detail here.
    </p>
    <p>
      Personas weren’t the only output of the research. I wrote several reports
      and I gave presentations. I shared the interview videos and I made
      compilation videos using clips from different interviews.
    </p>
    <SubjectHeading>Outcome</SubjectHeading>
    <p>
      The personas were a crucial part of a new product development strategy.
      Lyst ran weekly sprints with the aim of developing and testing new product
      ideas. The personas were used to help set up the initial ideas in a
      workshop and were then a reference point during the sprints.
    </p>
    <p>
      The video content that I shared started a lot of conversations too. This
      research was a big contributor to the research lunches that I ran after
      this project finished. I have a separate case study on that.
    </p>
    <SubjectHeading>Opportunities for improvement</SubjectHeading>
    <p>
      This project had a lot of ups and downs. And with such a big project,
      there is always a lot of room for improvement.
    </p>
    <p>
      My biggest mistake was the management of expectations around the survey. I
      have always been careful to make distinctions between what surveys are and
      are not good at measuring. However, expectations of this survey still
      managed to drift and ultimately the only person in a position to stop this
      was me.
    </p>
    <p>
      I also made the mistake of simply taking on far too much on my own. It was
      inherently a very big and difficult job, so I just bit the bullet. I think
      in retrospect I should have done less and been more stubborn about needing
      more user researchers.
    </p>
    <Footer />
  </Section>
);

export const FashionPersonas = (props) => (
  <Section contentVisible={props.contentVisible}>
    <SectionHeading>Fashion personas</SectionHeading>
    <ImageHolder>
      <ImageSizer>
        <Image
          alt="An example of the persona format we used"
          src={props.images(`./persona-full.png`)}
        />
      </ImageSizer>
      <Caption>A fake persona to illustrate the format that I used</Caption>
    </ImageHolder>
    <p>
      At the beginning of 2019, I created a set of personas for a series of
      workshops and design sprints.
    </p>
    <Objective>
      To provide a simple way to understand the target audience that could help
      workshop participants to come up with valid product ideas.
    </Objective>
    <SubjectHeading>Context and constraints</SubjectHeading>
    <p>
      I had already conducted a large amount of audience research that could
      provide the basis for the personas.
    </p>
    <p>
      There was only part of one week to decide upon the right archetypes,
      design the persona template and write all of the content.
    </p>
    <SubjectHeading>The use case</SubjectHeading>
    <p>
      Personas are a tool that need to be designed for their specific use case.
      So I started by thinking about the workshops that they were needed for in
      a week’s time.
    </p>
    <p>
      The workshops were based on part of the sprint process. The idea was that
      over the course of a day we would come up with a number of new product
      ideas that could then be prototyped and tested in weekly sprints.
    </p>
    <p>Each product idea would represent a completely separate new product.</p>
    <p>
      We needed something to help the workshop participants to think about the
      behaviours and needs of the target audience. I was asked for personas, but
      it wasn’t necessarily the case that personas were the right tool for the
      job.
    </p>
    <SubjectHeading>Personas or no personas</SubjectHeading>
    <p>
      We needed some kind of simplification of the all the data from the
      audience research, but there were several other options. I considered
      doing summaries of real research participants. I also considered doing
      something centred around specific fashion shopping use cases or scenarios.
    </p>
    <p>
      I had previously done a lot of reading about the pros and cons of
      personas, so I looked back over my notes. It seemed to me that there was a
      risk of oversimplification. There was also a risk that the personas might
      end up being used for things that they weren’t designed for.
    </p>
    <p>
      I decided that the best tool for the job would have to be simple and
      accessible, but also provide examples of the diversity of the underlying
      data.
    </p>
    <p>
      I don’t think that there was a right answer when it came to personas
      versus scenarios. So my colleague worked on a scenarios tool while I
      worked on the personas.
    </p>
    <p>
      The job of deciding which types of target audience to focus on was helped
      by the CEO narrowing down the directions that the business would consider
      going in to a more manageable range.
    </p>
    <SubjectHeading>Designing the personas</SubjectHeading>
    <p>
      I initially created 4 personas, based on archetypes that I found in the
      audience research. Then, after reviewing them with the team, I quickly
      came up with a 5th persona to fill a specific gap that someone identified.
    </p>
    <p>
      Normally, I would be worried that this might be too many personas. For an
      individual product, that might be the case (depending on the product).
      However, as a tool for developing lots of different product ideas, the
      number of personas needed to be determined by what was appropriate for the
      workshop format.
    </p>
    <p>
      Each persona contained some key behaviours and their main reason for
      shopping for clothes online. I also included a fake quote to sum up them
      up with a particular behaviour or attitude.
    </p>
    <p>
      Then, to balance these generalisations, I included two examples of real
      participants. The information for each participant was slightly more
      detailed in order to emphasise how much variety there is in the details
      for the group of people represented by each persona.
    </p>
    <p>
      I gave the personas a name, a gender and an image. With each of these
      things, there’s a risk of tapping into people’s preconceptions and
      distracting them from the actual data. However, it’s also important to
      make the personas memorable and easy to talk about.
    </p>
    <p>
      I used cartoons rather than real photos in order to stop people from being
      influenced by their faces or their outfits. I made sure that there was
      diversity in terms of gender and ethnicity across the personas, and for
      each individual persona I used the examples of real participants to
      provide more balance where appropriate.
    </p>
    <SubjectHeading>Outcome</SubjectHeading>
    <p>
      The personas played a key role in the workshops. From participating in the
      workshops myself, I felt that the other participants were engaging with
      them a lot. I also got a lot of feedback that the examples from the real
      participants were particularly useful, so I’m glad that I included them.
    </p>
    <SubjectHeading>Opportunities for improvement</SubjectHeading>
    <p>
      There were times during the weekly sprints where the correct role for the
      personas wasn’t entirely clear. I was happy for them to be a useful
      reference point when important decisions needed to be made, but overall I
      wanted them to be of secondary importance, behind the user testing
      results.
    </p>
    <p>
      If I’d had more time, I would have liked to lay out clearer guidelines on
      how to use the personas during this iteration process. Unfortunately,
      there really wasn’t time for half the things that needed to be done.
    </p>
    <p>
      Also, I left the company before we got to the stage where those workshops
      personas would have to be replaced. Any individual product idea would
      eventually need personas (or another tool) designed specifically for that
      product and the needs of that team.
    </p>
    <Footer />
  </Section>
);

export const RapidPrototyping = (props) => (
  <Section contentVisible={props.contentVisible}>
    <SectionHeading>Rapid app prototyping</SectionHeading>
    <ImageHolder>
      <ImageSizer>
        <Image
          alt="A laptop displaying a video call with a research participant"
          src={props.images(`./video-call-full.png`)}
        />
      </ImageSizer>
      <Caption>The set-up I used for moderated remote user testing</Caption>
    </ImageHolder>
    <p>
      We were given the task of developing new product ideas from scratch, with
      the aim of then releasing something alongside the existing product. User
      testing was a big part of this process.
    </p>
    <Objective>
      To provide feedback on the viability of new product ideas.
    </Objective>
    <SubjectHeading>Context and constraints</SubjectHeading>
    <p>
      We had been asked to develop 2 new product ideas in a quarter. This meant
      iterating on one of them in weekly sprints and then switching to the
      second idea and iterating on that.
    </p>
    <SubjectHeading>Research methods and recruitment</SubjectHeading>
    <p>
      The quickest and easiest option was to use remote unmoderated user testing
      with usertesting.com, but this only gave us very limited control over our
      recruitment. Since we had a specific target audience in mind, we also
      needed another method that allowed for more detailed recruitment.
    </p>
    <p>
      I felt that a combination of usertesting.com sessions and remote moderated
      user testing sessions using video calls would give us some control over
      the recruitment without slowing us down.
    </p>
    <p>
      We needed to be able to get as much useful feedback as possible in a very
      short period of time. So I decided to structure the week with two blocks
      of user testing.
    </p>
    <p>
      This meant that we had two opportunities to iterate on the prototype each
      week.
    </p>
    <ImageHolder>
      <ImageSizer>
        <Image
          alt="A sketch of the research schedule"
          src={props.images(`./research-schedule-full.png`)}
        />
      </ImageSizer>
      <Caption>A rough idea of the weekly research schedule</Caption>
    </ImageHolder>
    <p>
      We aimed to recruit people who fitted one of our personas as closely as
      possible. However, these people were sometimes quite hard to come by, so
      we had to be flexible.
    </p>
    <p>
      The moderated sessions were a combination of interview questions and
      prototype testing. We used the interview section to guage how well that
      person really fitted our persona, and to add to our overall understanding
      of fashion shoppers.
    </p>
    <SubjectHeading>User testing</SubjectHeading>
    <p>
      In the user testing, I looked out for whether the concept of the prototype
      was clear. I made note of UX problems that might be getting in the way of
      that understanding.
    </p>
    <p>
      I also needed to look out for signals indicating that the participant
      might use the product, were it real. Unfortunately, this isn't something
      that you can just ask someone and expect to get accurate information.
    </p>
    <p>
      If someone happened to say that they would love to use it, that made
      things a lot easier! If not, I asked them for their opinions and I asked
      them to compare it to any alternatives that they currently used. This
      information, along with their behaviour when using the prototype and what
      they had said about their shopping habits in general usually gave us a
      pretty good sense.
    </p>
    <p>
      After each interview finished, I quickly wrote up some notes from memory
      and shared them on the team Slack channel along with a link to the video.
      Then I usually had a quick debrief with the designer and the PM if they
      were free.
    </p>
    <p>
      I decided against doing any kind of report. We didn't have much time and
      this wasn't a priority.
    </p>
    <SubjectHeading>Outcome</SubjectHeading>
    <p>
      We learned a lot from the user testing and we were able to iterate on the
      prototypes quickly. I left the company before this process had time to
      bear any fruit, but I feel that I met the user research objective.
    </p>
    <SubjectHeading>Opportunities for improvement</SubjectHeading>
    <p>
      I plan to spend some time thinking about the best way to judge the
      potential of a product idea from a prototype. Our method was good, but I
      think there's room for improvement.
    </p>
    <Footer />
  </Section>
);

export const MakingUX = (props) => (
  <Section contentVisible={props.contentVisible}>
    <SectionHeading>Making the UX Score</SectionHeading>
    <ImageHolder>
      <ImageSizer>
        <Image
          alt="A laptop dsplaying Lyst's UX Score survey"
          src={props.images(`./ux-score-full.png`)}
        />
      </ImageSizer>
      <Caption>The UX Score prompt - photo taken in August 2019</Caption>
    </ImageHolder>
    <p>
      When I joined Lyst, they were using NPS. I replaced it with a bespoke
      system that avoided NPS's flaws and focused attention on the most valuable
      data.
    </p>
    <Objective>
      To make the best possible use of the opportunity to ask Lyst users a
      question on the website.
    </Objective>
    <SubjectHeading>Context and constraints</SubjectHeading>
    <p>
      Lyst had been using an NPS pop-up for a number of months when I joined the
      company. I took over tracking the score and reading the comments that
      people left.
    </p>
    <p>
      Senior people in the company were keen on using NPS as a way to track the
      user experience of the website and to benchmark Lyst’s user experience
      against competitors and industry averages.
    </p>
    <SubjectHeading>Challenging NPS</SubjectHeading>
    <p>
      The idea of asking your users a question and producing a single metric is
      a good one. However, I wanted Lyst to stop using NPS. There are two major
      problems with it: the NPS question and the way that the data is processed.
    </p>
    <p>
      We would be better off with a different question, a different format and a
      different way of calculating the metric.
    </p>
    <p>
      The CEO and COO were initially in favour of keeping NPS and one of the key
      reasons was the opportunity to benchmark Lyst’s NPS against other
      companies.
    </p>
    <p>
      Unfortunately, it’s too easy for companies to manipulate their NPS score,
      or simply implement it in different ways. For benchmark data to be useful,
      it would have to correct for this.
    </p>
    <p>
      I made my case to the CEO and COO and I was given their approval to go
      ahead.
    </p>
    <SubjectHeading>Improving the metric</SubjectHeading>
    <p>
      To make the metric as reliable as possible, we needed a question that made
      sense to users. With NPS, we received a lot of comments to the effect of
      “I don’t have any friends who would be interested.” We needed to avoid
      making a similar mistake.
    </p>
    <p>
      We also wanted a question that made sense in terms of the product
      strategy. I spoke to the CEO and other people in the business and got
      feedback on a few different options.
    </p>
    <p>
      The question we used was: “Would you be happy to use Lyst again?” This
      came from the desire to increase user retention. The specific wording was
      intended to make it easy to answer even if you had limited experience of
      the product.
    </p>
    <p>
      There was a problem with the number scale used in NPS too. An 11-point
      scale is too long for easy implementation on mobile screens.
    </p>
    <p>
      A long scale adds no more value than a shorter scale and if anything
      creates the opportunity for biases and noise in the data. So we needed a
      shorter scale.
    </p>
    <p>
      I tested several different scale lengths to see what effect they had on
      the metric, the response rate and the quality and quantity of comments.
      After testing a number of different options, we settled on a 7-point
      scale.
    </p>
    <p>
      For the metric, I used just the arithmetic mean of all the scores. It’s
      simple and it takes into account all of the data points, which the NPS
      calculation failed to do. We called it the UX Score.
    </p>
    <SubjectHeading>Focusing on the comments</SubjectHeading>
    <p>
      The metric needed to be as useful and reliable as possible, but I felt
      that it was more important to focus on getting high-quality comments from
      our users.
    </p>
    <p>
      The comments could tell us what people liked and what they didn’t like.
      They could uncover things that we didn’t know about. The metric could only
      tell us if we were getting better or worse overall.
    </p>
    <p>
      In an ideal world, we could tie the responses into our A/B tests and use
      that to determine what was causing the metric to change. Unfortunately, it
      wasn’t feasible to get the volume of responses we would need for
      statistical significance.
    </p>
    <p>
      So unfortunately the metric was always going to be quite limited in what
      it could do for us.
    </p>
    <SubjectHeading>Getting better comments</SubjectHeading>
    <p>
      The comments came from asking users a follow-up question after they
      submitted their answer to the first question.
    </p>
    <p>
      The follow-up question in the NPS system was very generic, which sometimes
      made it hard to interpret the comments. The comments were often just a few
      words, and without context it might not even be clear if they were praise
      or criticism.
    </p>
    <p>
      In the new system, we asked different follow-up questions depending on the
      number they chose. If they chose 7, we asked them what they liked the
      most. If they chose 4, 5 or 6, we asked them what we could improve, and if
      they chose 1, 2 or 3, we asked them what frustrated them.
    </p>
    <SubjectHeading>Outcome</SubjectHeading>
    <p>
      I was able to direct attention away from the metric and towards the
      comments, which meant that less time was spent trying to interpret a
      number that couldn’t tell us very much.
    </p>
    <p>
      I created a Slack channel where I posted interesting UX Score comments on
      a regular basis. This lead to a number of useful conversations about the
      product. The research Slack channel was one of the largest in the company,
      with people from every team.
    </p>
    <SubjectHeading>Opportunities for improvement</SubjectHeading>
    <p>
      I really wanted to create a website survey toolkit, rather than just have
      a single static question. It was inefficient to ask everyone the same
      question regardless of their behaviour on the website.
    </p>
    <p>
      I suggested this on a number of occasions to different people across the
      business and made a case for how it could benefit them. I also submitted
      it as a hack-day project. Unfortunately, I wasn’t able to make any
      progress with this.
    </p>
    <Footer />
  </Section>
);

export const ResearchVideo = (props) => (
  <Section contentVisible={props.contentVisible}>
    <SectionHeading>Research video lunches</SectionHeading>
    <ImageHolder>
      <ImageSizer>
        <Image
          alt="Stacks of pizza boxes"
          src={props.images(`./pizza-full.png`)}
        />
      </ImageSizer>
      <Caption>Pizza changes everything</Caption>
    </ImageHolder>
    <p>
      I used lots of different methods for sharing research findings in my time
      at Lyst, but the research lunches were definitely the most popular.
    </p>
    <Objective>
      To encourage people across the company to think more about our users and
      to better disseminate the information we had about our target audience.
    </Objective>
    <SubjectHeading>Context and constraints</SubjectHeading>
    <p>
      We had a lot of video content from research interviews with Lyst’s users
      and other online fashion shoppers.
    </p>
    <SubjectHeading>The idea</SubjectHeading>
    <p>
      On a few occasions, my line manager suggested the idea of putting on
      sessions for people to watch research videos. Initially, I wasn’t in
      favour of it.{" "}
    </p>
    <p>
      I was sceptical that people would want to give up an hour of work in order
      to attend. Generally speaking, most people didn’t have an hour just going
      spare.
    </p>
    <p>
      I was told that we could make attendance mandatory, but I felt that this
      would encourage people to see research as a nuisance.
    </p>
    <p>
      After having this conversation pop up every now and then, I was told that
      maybe budget could be made available for lunch for all the attendees. This
      changed everything. People love free lunch. Now there wouldn’t be any need
      to force people to attend.
    </p>
    <SubjectHeading>Execution</SubjectHeading>
    <p>
      We ran hour-long videos of remote research interviews during lunch on
      Wednesdays. This lasted for a quarter.
    </p>
    <p>
      I selected the most interesting or entertaining videos for the first few
      sessions in order to create enthusiasm and momentum.
    </p>
    <p>
      I spent some time editing each video so that the participant’s face was
      more prominent and mine less so. This was intended to focus their
      attention on their facial expressions rather than mine.
    </p>
    <p>
      Lyst has a large space with a big screen near the main kitchen that can be
      sectioned off for meetings. Before each session, I only needed to
      rearrange the furniture a bit. It made for a great cinema room.
    </p>
    <SubjectHeading>Outcome</SubjectHeading>
    <p>
      The research lunches were a big hit. Lots of people attended them and
      spoke highly of them.
    </p>
    <p>
      I can’t tie them to any specific product outcomes, but people would come
      and talk to me about the participants and there would sometimes be
      discussions about them on the research Slack channel.
    </p>
    <SubjectHeading>Opportunities for improvement</SubjectHeading>
    <p>
      The plan was to keep the research lunches going beyond that initial
      quarter. We would conduct more interviews, which would produce more video
      content for the sessions.
    </p>
    <p>
      However, difficulties with the budget at the end of the year put a spanner
      in the works. This probably would have only been a temporary problem, but
      I left Lyst not long after this.
    </p>
    <p>
      I feel like the better solution would have been to have a research budget
      that was large enough for conducting research and communicating it. Having
      the research lunches come from another budget created unnecessary
      complexity.
    </p>
    <Footer />
  </Section>
);

export const FixingRetros = (props) => (
  <Section contentVisible={props.contentVisible}>
    <SectionHeading>Fixing retros</SectionHeading>
    <ImageHolder>
      <ImageSizer>
        <Image
          alt="Some post-it notes"
          src={props.images(`./retro-full.png`)}
        />
      </ImageSizer>
      <Caption>We used to do retros with post-it notes on the wall</Caption>
    </ImageHolder>
    <p>
      In April 2018 I found out that the other two UX people at Lyst were
      leaving. The UX team needed to be rebuilt and I felt that the UX retros
      needed to play a larger role.
    </p>
    <Objective>
      To make sure that the UX team is happy and productive.
    </Objective>
    <SubjectHeading>Context and constraints</SubjectHeading>
    <p>
      The researchers and designers were embedded in multi-disciplinary teams,
      but we also maintained a notional UX team to make sure that we kept in
      regular contact.
    </p>
    <p>
      I inherited the responsibility for the UX retro when there was a period of
      turnover in UX team a year before I left. At that time, it involved a
      fortnightly session of sticking post-it notes on a wall, followed by a
      brief write-up on Confluence.
    </p>
    <SubjectHeading>Inefficiency</SubjectHeading>
    <p>
      One of the problems we had with UX retros was that we always overran the
      hour. A significant part of the session was spent writing things down on
      post-it notes, rather than discussing the issues.
    </p>
    <p>
      Then after the retro, someone needed to go through those post-it notes and
      type up the main points. This was very time consuming, and most often it
      just didn’t happen.
    </p>
    <SubjectHeading>Lack of action</SubjectHeading>
    <p>
      Failure to type the notes up meant that the output of these retros was
      easily forgotten. I felt that we needed to do a better job of tracking the
      issues that came up in the retros.
    </p>
    <p>
      By keeping a record of what the team was going through over time, we could
      make sure that problems would be identified and addressed.
    </p>
    <p>
      Another problem with the old format was that when we did type up the
      notes, they were saved on Confluence, which anyone in the company could
      access. For these retros to work, I needed everyone to feel like they
      could speak freely. So privacy was essential.
    </p>
    <SubjectHeading>Missing data</SubjectHeading>
    <p>
      We held our retro once every fortnight, which meant that it was difficult
      to remember everything important that had happened since the previous
      retro.
    </p>
    <p>
      There was also a problem with people missing retros because of illness,
      holidays or workload. If that happened, they would have to remember
      everything from four or maybe six weeks ago.
    </p>
    <p>
      One solution would have been to make the retros more frequent, but nobody
      in the team wanted an extra meeting.
    </p>
    <p>
      Instead, we needed a way for everyone to record their thoughts day by day,
      rather than all at once on a specific day.
    </p>
    <SubjectHeading>The solution</SubjectHeading>
    <p>
      The new system was pretty simple. I created a shared Google Sheet with a
      tab for each individual retro session. Only people in the UX team had
      access to it.
    </p>
    <p>
      Instead of writing things on post-it notes, we added rows to the
      spreadsheet. I set up calendar reminders to encourage people to do this on
      a regular basis.
    </p>
    <p>
      The day before the retro, I sent out a final reminder on Slack and then
      organised all of the rows in the spreadheet into themes.
    </p>
    <p>
      Then, in the retro itself, we put the spreadsheet up on the screen and
      went through it theme by theme.
    </p>
    <p>
      At the end of each retro, I added a few bullet points to summarise the key
      actions that needed to be taken.
    </p>
    <SubjectHeading>Outcome</SubjectHeading>
    <p>We saved a lot of post-it notes, which is a good side effect.</p>
    <p>
      We were able to get through a lot more things in the hour we had for each
      retro, and there was no need to then spend time writing anything up.
    </p>
    <p>
      I experimented with doing a quarterly report looking at the long-term
      themes and any important changes we had made. This was useful, but after a
      couple of quarters I had to stop to save time.
    </p>
    <p>
      The summary for each retro made it easy to look back over recent retros
      and talk about what had changed for the better and what still needed to be
      done. It was really important to celebrate the progress we had made and to
      stay vigilant about stubborn problems.
    </p>
    <p>
      The retros were a really useful tool during a stressful period at work for
      the team. The culture of trust and openness was very important.
    </p>
    <SubjectHeading>Opportunities for improvement</SubjectHeading>
    <p>
      I found that people ended up contributing most of their retro notes on the
      day of the retro. This was fine, but it meant that people might have been
      forgetting things that happened earlier in the fortnight.
    </p>
    <p>
      The calendar reminders might have helped a bit, but I would like to
      experiment with other ways to do this in the future.
    </p>
    <Footer />
  </Section>
);

export const ResearchHiring = (props) => (
  <Section contentVisible={props.contentVisible}>
    <SectionHeading>User research hiring process</SectionHeading>
    <ImageHolder>
      <ImageSizer>
        <Image
          alt="The cover page for one of the Lyst user research interview tasks"
          src={props.images(`./tradar-full.png`)}
        />
      </ImageSizer>
      <Caption>
        One of the interview tasks I used was a fake research report
      </Caption>
    </ImageHolder>
    <p>
      Towards the end of 2017, I was given the budget to hire a second user
      researcher. I decided to spend some time developing a hiring process for
      this role.
    </p>
    <Objective>
      To hire a second user researcher who has the skills to work independently
      and to a high standard.
    </Objective>
    <SubjectHeading>Context and constraints</SubjectHeading>
    <p>There was no existing hiring interview process for user researchers.</p>
    <p>
      The interview process for designers involved three hours of interview
      time, usually spread across two or three days. The in-house recruitment
      team expected me to stay close to this format
    </p>
    <SubjectHeading>Treating hiring like research</SubjectHeading>
    <p>
      I decided to approach the process of designing a hiring interview process
      in the same way that I would approach designing a piece of research.
    </p>
    <p>
      The research objective was to understand each candidate’s research skills
      as clearly as possible. For this reason, I wanted to rely as much as
      possible on demonstrations of skill, rather than testimony (e.g. a CV).
    </p>
    <p>
      The skills that I was most keen to understand were research design,
      moderation and interpretation of research findings.{" "}
    </p>
    <p>
      From my experience of being the candidate in research interviews, I knew
      that two techniques were common: asking candidates for examples of
      research that they had done in the past and asking candidates what they
      would do in a particular scenario.
    </p>
    <p>
      I chose to use, where possible, detailed and realistic scenarios to assess
      the candidates’ skills. Discussion of past work is useful, but it doesn’t
      necessarily portray how they really work.
    </p>
    <SubjectHeading>Learning from other interviews</SubjectHeading>
    <p>
      I also had experience from running (and tweaking) the scenario-based
      challenge that we use for designer interviews at Lyst. I had observed in
      those interviews that if the scenario wasn’t detailed enough, the
      candidates would often drop the pretence of the scenario and gloss over
      things.
    </p>
    <p>
      I wanted to use scenarios that the candidates could treat like a real job
      without worrying that they couldn’t ask too many questions because there
      might not be answers.
    </p>
    <p>
      I tried to make sure that as much of the work took place during the
      interviews, rather than as at-home tasks.
    </p>
    <p>
      I have been asked to do at-home tasks before and I'm uneasy about it.
      There is a risk that people with more free time will have an unfair
      advantage and that people with other responsibilities, such as childcare,
      will be discriminated against.
    </p>
    <p>
      One of the scenarios I used involved asking the participant to read
      through a fake research report in their own time. I then asked them a few
      questions about it in the interview. I felt that this was small and simple
      enough that it wouldn't be a burden on anyone.
    </p>
    <SubjectHeading>Interview format</SubjectHeading>
    <p>
      The first interview included an exercise where I asked the candidates to
      write down different research methods on post-it notes and then group them
      (like in a card sort).
    </p>
    <p>
      This exercise was used to understand how the candidates thought about user
      research, its purpose and its scope.
    </p>
    <p>
      The second interview contained a series of research design scenarios. In
      each one, the candidate was asked to imagine that they have just joined a
      start-up and that a colleague wanted to get their opinion about a
      particular project.
    </p>
    <p>
      For each scenario, I had a list of details and prepared answers so that I
      could respond to the candidate’s questions.
    </p>
    <p>
      The third interview focused on a research analysis scenario. The scenario
      was that they had just joined a start-up and were picking up some work
      that a contractor had finished the previous week.
    </p>
    <p>
      The candidates were all given a fake research report a couple of days
      before the interview and were asked to read it. Then in the interview
      itself, they were asked to offer their opinions on what the company should
      do next.
    </p>
    <SubjectHeading>Outcome</SubjectHeading>
    <p>
      Most importantly, we were able to hire a very capable user researcher.
    </p>
    <p>
      I feel that the scenarios did their job well: they helped us to identify
      strengths and weaknesses in all of the candidates.
    </p>
    <p>
      I was able to implement the research design scenarios without there being
      a need to for anyone to break character. The candidates asked questions
      and generally approached the scenarios in a way that seemed realistic to
      me.
    </p>
    <SubjectHeading>Opportunities for improvement</SubjectHeading>
    <p>
      The research analysis scenario was slightly less effective, because in
      real life the candidates would have been able to look at the product that
      was the subject of the research report.
    </p>
    <p>
      I hope to find time to build a prototype of this fictional app to help
      make this scenario more realistic.
    </p>
    <p>
      I would like to have had the time to do something similar for the product
      design hiring process. We made a few improvements to that process, but I
      feel that it could have benefitted from an overhaul.
    </p>
    <Footer />
  </Section>
);
