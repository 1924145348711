import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createGlobalStyle, ThemeProvider } from "styled-components";

const theme = {
  mainGrey: "#302b26",
  midGrey: "#504C48",
  paleGrey: "#cccccc",
  cyan: "#00d5e4",
  darkBlue: "#191d32",
  backdropBrown: "#f5eee9",
  brightGreen: "#39dd4f",
  paleGreen: "#bcffc5",
  standardSpacing: "2rem",
  headerFont: "Literata, serif",
  smallCapsFont: "Literata, serif",
  bodyFont: "Helvetica Neue, sans-serif",
  midBreak: "40rem",
  smallBreak: "30rem"
};

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;
  }
  body {
    margin: 0;
    font-size: 2rem;
    font-family: ${theme.bodyFont};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${theme.mainGrey};
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    
    @media (max-width: ${theme.smallBreak}), (max-device-width: 680px) and (orientation: portrait) {
      font-size: 1.8rem;
    }
  }
  p {
    margin-bottom: ${theme.standardSpacing};
    margin-top: ${theme.standardSpacing};
  }
  p,
  li {
    line-height: 3.4rem;

    @media (max-width: ${theme.smallBreak}), (max-device-width: 680px) and (orientation: portrait) {
      line-height: 2.8rem;
    }
  }
  ul {
    @media (max-width: ${theme.smallBreak}), (max-device-width: 680px) and (orientation: portrait) {
      padding-left: 2.4rem;
    }
  }
  li {
    padding-left: 0.75rem;
    padding-bottom: 0.75rem;
  }
  a,
  a:visited,
  a:link {
    text-decoration: none;
    color: ${theme.mainGrey};
    border-bottom: 2px ${theme.cyan} solid;
    transition: color 0.2s;
  }
  a:hover {
    border-color: ${theme.darkBlue};
    transition: border-color 0.2s;
  }
  a:focus {
    outline: 2px dashed ${theme.paleGrey};
    outline-offset: 2px;
  }
`;

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <App />
      <GlobalStyle />
    </React.Fragment>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
