import React from "react";
import Footer from "./Footer";
import styled from "styled-components";
import { Section, SectionHeading } from "./SharedStyles";

const LinkList = styled.ul`
  list-style: none;
  padding-left: 1rem;

  li {
    padding-left: 3rem;
    text-indent: -3rem;

    &:before {
      content: "👉🏻 ";
    }
  }

  @media (max-width: ${props => props.theme.midBreak}) {
    padding-left: 0rem;
  }
`;

const Writing = props => {
  return (
    <Section contentVisible={props.contentVisible}>
      <SectionHeading>I've written a few articles on Medium</SectionHeading>
      <LinkList>
        <li>
          <a href="https://uxdesign.cc/the-problem-with-nps-benchmarking-ae6dd37df2fb">
            The problem with NPS benchmarking
          </a>
        </li>
        <li>
          <a href="https://medium.com/designing-lyst/why-user-researchers-should-care-about-sample-size-fbb0b4215b3a">
            Why user researchers should care about sample size
          </a>
        </li>
      </LinkList>
      <Footer />
    </Section>
  );
};

export default Writing;
