import React from "react";
import Footer from "./Footer";
import styled from "styled-components";
import {
  Section,
  SectionHeading,
  SubsectionHeading,
  SubjectHeading,
  ExampleCard,
  ExampleImageHolder,
  ExampleImage,
  ExampleTextHolder,
  ExampleHeading,
  ExampleDescription,
  ReadMore
} from "./SharedStyles";
import { articleList } from "./PortfolioArticles";

const AnchorList = styled.ol`
  padding: 0 2.5rem;
  z-index: 10;
  position: relative;

  li {
    padding: 0;
    span {
      &::after {
        content: "";
        border: none;
        opacity: 0;
        transition: opacity 0.2s;
      }

      &:hover::after {
        content: " 👇🏻";
        opacity: 1;
      }
    }

    @media (hover: none), (-moz-touch-enabled: 1), (pointer: coarse) {
      padding-bottom: 0.75rem;
    }
  }
`;

const PortfolioSection = styled.section`
  position: relative;
`;

const ToTopLink = styled.span`
  background-color: none;
  font-size: 1.6rem;
  font-family: ${props => props.theme.bodyFont};
  font-variant: normal;
  font-weight: 400;
  margin-left: 2rem;

  a {
    border-bottom: 1px solid ${props => props.theme.mainGrey};

    &:visited {
      border-bottom: 1px solid ${props => props.theme.mainGrey};
    }

    &:link {
      border-bottom: 1px solid ${props => props.theme.mainGrey};
    }

    &:hover {
      color: unset;
    }
  }
  &::after {
    font-size: 2rem;
    content: "";
    border: none;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover::after {
    content: " 👆🏻";
    opacity: 1;
  }

  @media (max-width: ${props => props.theme.smallBreak}),
    (max-device-width: 680px) and (orientation: portrait) {
    display: block;
    margin-left: 0;

    &::after {
      font-size: 1.8rem;
    }
  }
`;

const PortfolioLink = props => {
  return (
    <li>
      <span>
        <a href={"#" + props.name.replace(/\s+/g, "-").toLowerCase()}>
          {props.name}
        </a>
      </span>
    </li>
  );
};

const PortfolioCategory = props => {
  return (
    <PortfolioSection>
      <SubsectionHeading
        isAnchor={true}
        id={props.name.replace(/\s+/g, "-").toLowerCase()}
      >
        <span>
          {props.sectionIndex + 1}. <span>{props.name.substring(0, 1)}</span>
          {props.name.substring(1)}
        </span>
        <ToTopLink>
          <a href="#navbar-holder">Back to top</a>
        </ToTopLink>
      </SubsectionHeading>
      <SubjectHeading>My approach</SubjectHeading>
      <p>{props.approach}</p>
      <SubjectHeading>Case studies</SubjectHeading>
      {props.examples.map((article, articleIndex) => {
        const fullImage = images(`./${article.image}-full.png`);
        const smallImage = images(`./${article.image}-small.png`);
        return (
          <ExampleCard
            key={articleIndex}
            tabIndex="0"
            aria-label={`Case study: ${article.title}`}
            onClick={() =>
              props.handleNavChange("portfolio", true, article.slug, true)
            }
          >
            <ExampleImageHolder aria-hidden="true">
              <picture>
                <source
                  media={`(min-width: 34.05rem) and (orientation: landscape),
                          (min-width: 34.05rem) and (orientation: portrait)
                          and (min-device-width: 768px)`}
                  srcSet={smallImage}
                />
                <ExampleImage src={fullImage} />
              </picture>
            </ExampleImageHolder>
            <ExampleTextHolder>
              <div>
                <ExampleHeading aria-hidden="true">
                  {article.title}
                </ExampleHeading>
                <ExampleDescription>{article.summary}</ExampleDescription>
              </div>
              <ReadMore>
                <span>Read more</span> →
              </ReadMore>
            </ExampleTextHolder>
          </ExampleCard>
        );
      })}
    </PortfolioSection>
  );
};

const Portfolio = props => {
  return (
    <Section contentVisible={props.contentVisible}>
      <SectionHeading>Examples of my work</SectionHeading>
      <p>Click on the links below to skip to a particular section.</p>
      <AnchorList className="anchor-list">
        {articleList.map((section, index) => (
          <PortfolioLink name={section.name} key={index} />
        ))}
      </AnchorList>
      {articleList.map((section, index) => (
        <PortfolioCategory
          name={section.name}
          approach={section.approach}
          examples={section.examples}
          key={index}
          sectionIndex={index}
          handleNavChange={props.handleNavChange}
        />
      ))}
      <Footer />
    </Section>
  );
};

const images = require.context("./img", true);

export default Portfolio;
