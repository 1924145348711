import React from "react";
import { ThisWebsite, QuizBuzzers, RiddleBook } from "./ProjectArticles";
import {
  LystAudience,
  FashionPersonas,
  RapidPrototyping,
  MakingUX,
  ResearchVideo,
  FixingRetros,
  ResearchHiring
} from "./PortfolioArticles";

const images = require.context("./img", true);

const Article = props => {
  return (
    <React.Fragment>
      <LystAudience
        contentVisible={props.articleSlug === "lyst-audience"}
        images={images}
      />
      <FashionPersonas
        contentVisible={props.articleSlug === "fashion-personas"}
        images={images}
      />
      <RapidPrototyping
        contentVisible={props.articleSlug === "rapid-app-prototyping"}
        images={images}
      />
      <MakingUX
        contentVisible={props.articleSlug === "making-the-ux-score"}
        images={images}
      />
      <ResearchVideo
        contentVisible={props.articleSlug === "research-video-lunches"}
        images={images}
      />
      <FixingRetros
        contentVisible={props.articleSlug === "fixing-retros"}
        images={images}
      />
      <ResearchHiring
        contentVisible={props.articleSlug === "user-research-hiring-process"}
        images={images}
      />
      <ThisWebsite
        contentVisible={props.articleSlug === "this-website"}
        images={images}
      />
      <QuizBuzzers
        contentVisible={props.articleSlug === "quiz-buzzers"}
        images={images}
      />
      <RiddleBook
        contentVisible={props.articleSlug === "a-book-of-riddles"}
        images={images}
      />
    </React.Fragment>
  );
};

export default Article;
