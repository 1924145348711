import React from "react";
import Footer from "./Footer";
import { Section, SectionHeading } from "./SharedStyles";

const Missing = props => {
  return (
    <Section contentVisible={props.contentVisible}>
      <SectionHeading>Uh oh</SectionHeading>
      <p>Something went wrong there.</p>
      <Footer />
    </Section>
  );
};

export default Missing;
