import React, { Component } from "react";
import Writing from "./Writing";
import Contact from "./Contact";
import CV from "./CV";
import Portfolio from "./Portfolio.jsx";
import Projects from "./Projects";
import Navbar from "./Navbar";
import Article from "./Article";
import Missing from "./404";
import ErrorToast from "./Error";
import styled from "styled-components";
import { Wrapper } from "./SharedStyles";

const AppHeader = styled.header`
  margin-top: 15rem;

  @media (max-width: ${props => props.theme.midBreak}) {
    margin-top: 8rem;
  }
`;

const FadeDiv = styled.div`
  transform: ${props => (props.visible ? "" : "translateX(3rem)")};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 1s ${props => props.fadeLevel * 0.4}s,
    transform 1s ${props => props.fadeLevel * 0.4}s;
  position: relative;
  height: 100%;
`;

const MainHeading = styled.h1`
  margin-bottom: ${props => props.theme.standardSpacing};
  margin-top: ${props => props.theme.standardSpacing};
  font-family: ${props => props.theme.headerFont};
  font-size: 5rem;
  font-weight: 600;

  @media (max-width: 38.75rem) {
    font-size: 4rem;
  }

  @media (max-width: ${props => props.theme.smallBreak}),
    (max-device-width: 680px) and (orientation: portrait) {
    font-size: 3.4rem;
  }
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.navbarRef = React.createRef();
    this.state = {
      contentLabels: [
        "contact",
        "cv",
        "portfolio",
        "writing",
        "other-projects",
        "article",
        "404"
      ],
      lastScroll: 0,
      activeContent: "portfolio",
      articleActive: false,
      articleSlug: "",
      fadeInStatus: false,
      navStuck: false,
      pageFill: 0,
      error: false,
      errorHeading: "",
      errorMessage: ""
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleStickyNav);
    window.addEventListener("resize", this.updatePageFillHeight);
    window.addEventListener("popstate", this.handlePopstate);
    const path = window.location.pathname;
    const pathArray = path.split("/").filter(s => s.length > 0);
    if (pathArray.length === 0) {
      setTimeout(() => {
        this.setState({ fadeInStatus: true });
      }, 100);
      window.history.replaceState(
        { content: "portfolio", articleActive: false },
        "",
        ""
      );
    } else {
      const contentArea = pathArray[0];
      if (
        this.state.contentLabels.includes(contentArea) &&
        pathArray.length < 3
      ) {
        if (pathArray.length === 1) {
          this.handleContentChange(contentArea);
          setTimeout(() => {
            this.setState({ fadeInStatus: true });
          }, 100);
          // You used to remove the fadeClass here
          window.history.replaceState({ contentArea: contentArea }, "", path);
        } else if (pathArray.length === 2) {
          const contentArea = pathArray[0];
          const articleSlug = pathArray[1];
          this.handleContentChange(contentArea, true, articleSlug, false);
          setTimeout(() => {
            this.setState({ fadeInStatus: true });
          }, 100);
          // You used to remove the fadeClass here
          window.history.replaceState(
            {
              contentArea: contentArea,
              articleActive: true,
              articleSlug: articleSlug
            },
            "",
            path
          );
        }
      } else {
        this.handleContentChange("404", false, "", false);
        setTimeout(() => {
          this.setState({ fadeInStatus: true });
        }, 100);
        // You used to remove the fadeClass here
        window.history.replaceState({ content: "404" }, "", "/404");
      }
    }
    this.updatePageFillHeight();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleStickyNav);
    window.removeEventListener("resize", this.updatePageFillHeight);
    window.removeEventListener("popstate", this.handlePopstate);
  }

  convertNameToSlug = name => {
    return name
      .toLowerCase()
      .split(" ")
      .join("-");
  };

  convertSlugToName = path => {
    let name = path.replace("-", " ");
    if (name === "cv") return "CV";
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  handlePopstate = event => {
    if (event.state !== null) {
      this.loadHistoryPage(event);
    }
  };

  loadHistoryPage = event => {
    console.log(event);
    const { contentArea, articleActive, articleSlug } = event.state;
    this.handleContentChange(contentArea, articleActive, articleSlug, false);
  };

  updatePageFillHeight = () => {
    let navBarHeight = this.navbarRef.current.clientHeight;
    if (window.innerWidth <= 480 && this.state.activeContent !== "article") {
      navBarHeight = navBarHeight / 2;
    }
    this.setState({ pageFill: window.innerHeight - navBarHeight + 2 });
  };

  handleNavChange = (
    contentArea,
    articleActive = false,
    articleSlug = "",
    newPage = true
  ) => {
    const prevArticleActive = this.state.articleActive;
    const contentAreaSlug = this.convertNameToSlug(contentArea);
    this.handleContentChange(
      contentAreaSlug,
      articleActive,
      articleSlug,
      newPage
    );
    this.updatePageFillHeight();
    this.handleScrollPosition(articleActive, prevArticleActive);
  };

  handleScrollPosition = (articleActive, prevArticleActive) => {
    const navBarHolder = this.navbarRef.current;
    let navPos = this.getTopOffset(navBarHolder);
    if (window.innerWidth <= 480 && articleActive === false) {
      navPos += navBarHolder.clientHeight / 2;
    }
    const stickyThreshhold = navPos - window.top.scrollY + 280;
    if (prevArticleActive === true) {
      setTimeout(() => {
        window.scrollTo(0, this.state.lastScroll);
        console.log(`lastScroll used: ${this.state.lastScroll}`);
      }, 200);
    } else if (this.state.navStuck === true) {
      setTimeout(() => {
        window.scrollTo(0, navPos + 2);
      }, 200);
    } else if (window.innerHeight < stickyThreshhold) {
      this.doScrolling(navPos, 500);
    }
  };

  getTopOffset = elem => {
    let distance = 0;
    do {
      distance += elem.offsetTop;
      elem = elem.offsetParent;
    } while (elem);

    return distance;
  };

  doScrolling = (elementY, duration) => {
    var startingY = window.pageYOffset;
    var diff = elementY - startingY;
    var start;
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      var time = timestamp - start;
      var percent = Math.min(time / duration, 1);
      window.scrollTo(0, startingY + diff * percent);
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  };

  handleStickyNav = () => {
    const navBarHolder = this.navbarRef.current;
    const stickyThreshhold = this.getTopOffset(navBarHolder);
    const currentScrollPos = window.top.scrollY;

    if (currentScrollPos > stickyThreshhold) {
      this.setState({ navStuck: true });
      // This is so that the smaller nav height difference on mobile is accounted for.
      this.updatePageFillHeight();
    } else {
      this.setState({ navStuck: false });
    }
  };

  handleContentChange = (
    contentArea,
    articleActive = false,
    articleSlug = "",
    newPage = true
  ) => {
    this.closeError();
    this.setState(prevState => {
      if (
        !prevState.articleActive &&
        (prevState.activeContent === "portfolio" ||
          prevState.activeContent === "other-projects")
      ) {
        prevState.lastScroll = window.top.scrollY;
        console.log(`lastScroll set: ${window.top.scrollY}`);
      }
      prevState.articleActive = articleActive;
      prevState.articleSlug = articleSlug;
      prevState.activeContent = contentArea;
      return { prevState };
    });
    if (newPage === true) {
      let path = `/${contentArea}`;
      if (articleActive === true) {
        path = `/${contentArea}/${articleSlug}`;
      }
      window.history.pushState(
        {
          contentArea: contentArea,
          articleActive: articleActive,
          articleSlug: articleSlug
        },
        "",
        path
      );
    }
  };

  handleError = (heading, message) => {
    this.setState({
      error: true,
      errorHeading: heading,
      errorMessage: message
    });
  };

  closeError = () => {
    this.setState({
      error: false
    });
  };

  render() {
    return (
      <Wrapper autoFocus>
        <AppHeader>
          <FadeDiv visible={this.state.fadeInStatus} fadeLevel={0}>
            <MainHeading>Hi! I'm Joseph Kay.</MainHeading>
          </FadeDiv>

          <FadeDiv visible={this.state.fadeInStatus} fadeLevel={1}>
            <p>I work as a user researcher in London.</p>
            <p>
              I have experience with a wide range of research methods,
              qualitative and quantitative. I also like to dabble in
              programming and writing.
            </p>
            <p>
              And... I'm currently available for contract roles!{" "}
              <span role="img" aria-label="celebration emoji">
                🎉
              </span>
            </p>
            <p>Since you're here, you might be looking for one of these:</p>
          </FadeDiv>
        </AppHeader>

        <FadeDiv visible={this.state.fadeInStatus} fadeLevel={2}>
          <Navbar
            handleNavChange={this.handleNavChange}
            activeContent={this.convertSlugToName(this.state.activeContent)}
            articleActive={this.state.articleActive}
            navStuck={this.state.navStuck}
            navbarRef={this.navbarRef}
          />
        </FadeDiv>
        <FadeDiv visible={this.state.fadeInStatus} fadeLevel={2}>
          <main>
            <Contact
              contentVisible={
                this.state.activeContent === "contact" ? true : false
              }
              key="contact"
              handleError={this.handleError}
            />
            <Portfolio
              contentVisible={
                this.state.activeContent === "portfolio" &&
                  this.state.articleActive === false
                  ? true
                  : false
              }
              key="portfolio"
              handleNavChange={this.handleNavChange}
            />
            <CV
              contentVisible={this.state.activeContent === "cv" ? true : false}
              key="cv"
            />
            <Writing
              contentVisible={
                this.state.activeContent === "writing" ? true : false
              }
              key="writing"
            />
            <Projects
              contentVisible={
                this.state.activeContent === "other-projects" &&
                  this.state.articleActive === false
                  ? true
                  : false
              }
              key="projects"
              handleNavChange={this.handleNavChange}
            />
            <Article
              contentVisible={this.state.articleActive}
              key="article"
              activeContent={this.state.activeContent}
              articleSlug={this.state.articleSlug}
            />
            <Missing
              contentVisible={this.state.activeContent === "404" ? true : false}
              key="missing"
            />
          </main>
        </FadeDiv>
        <div style={{ height: this.state.pageFill }} />
        <ErrorToast
          heading={this.state.errorHeading}
          message={this.state.errorMessage}
          errorActive={this.state.error}
          closeError={this.closeError}
        />
      </Wrapper>
    );
  }
}

export default App;
