import React, { Component } from "react";
import styled from "styled-components";
import { Wrapper } from "./SharedStyles";

const Toast = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20rem;
  background-color: #ffbcbc;
  border-top: 3px solid #dd3939;
  transform: ${props => (props.errorActive ? "" : "translateY(25rem)")};
  transition: transform 0.5s;
  z-index: 100;

  h4 {
    margin: 1.5rem 0;
    width: 90%;
  }

  p {
    width: 90%;
    font-size: 1.6rem;
    margin: 0;
    line-height: 2.2rem;
  }
`;

const Close = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 3rem;
  height: 3rem;

  &:hover {
    cursor: pointer;
  }

  &:before,
  &:after {
    position: absolute;
    left: 1.5rem;
    content: " ";
    height: 3rem;
    width: 0.3rem;
    background-color: #333;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const ErrorToast = props => {
  return (
    <Toast errorActive={props.errorActive}>
      <Wrapper>
        <h4>{props.heading}</h4>
        <p>{props.message}</p>
        <Close onClick={props.closeError} />
      </Wrapper>
    </Toast>
  );
};

export default ErrorToast;
