import React, { Component } from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
  width: 100%;
  height: 18rem;
  p {
    font-size: 1.8rem;
    padding-top: 2.4rem;
  }
`;

class Footer extends Component {
  state = {};
  render() {
    return (
      <StyledFooter>
        <p>© 2021 Joseph Kay - all rights reserved</p>
      </StyledFooter>
    );
  }
}

export default Footer;
